/**
 * Created by Tom on 10.11.2016.
 */
import 'element-closest'
import selectAll from './select-all'

function toggleContainer (elem, tabs, parent) {
  let aktID = elem.getAttribute('data-id')
  let tabContent = selectAll('.toggle-container', parent)
  let tab = selectAll('.toggle-link', parent)
  let setTab = false
  if (!document.getElementById(aktID).classList.contains('show')) {
    setTab = true
  }
  for (let y = tabContent.length - 1; y >= 0; y--) {
    tabContent[ y ].classList.remove('show')
  }
  for (let z = tab.length - 1; z >= 0; z--) {
    tab[ z ].classList.remove('active')
  }
  if (setTab) {
    document.getElementById(aktID).classList.add('show')
    elem.classList.add('active')
  }
}

function toggleContainerFAQ (elem, tabs, parent) {
  let aktID = elem.getAttribute('data-id')
  let tabContent = selectAll('.toggle-container-faq', parent)
  let tab = selectAll('.toggle-link-faq', parent)
  let setTab = false
  if (!document.getElementById(aktID).classList.contains('show')) {
    setTab = true
  }
  for (let y = tabContent.length - 1; y >= 0; y--) {
    tabContent[ y ].classList.remove('show')
  }
  for (let z = tab.length - 1; z >= 0; z--) {
    tab[ z ].classList.remove('active')
  }
  if (setTab) {
    document.getElementById(aktID).classList.add('show')
    elem.classList.add('active')
  }
}
export default function initToggle () {
  let toggleLink = selectAll('.toggle-link')
  if (toggleLink !== null) {
    for (let i = toggleLink.length - 1; i >= 0; i--) {
      toggleLink[ i ].addEventListener('click', function (event) {
        event.stopPropagation()
        let parent = null
        if (parent != null) {
          parent = event.target.closest('.faq-wrapper')
        }
        toggleContainer(this, toggleLink, parent)
        return false
      })
    }
  }
  let toggleLinkFaq = selectAll('.toggle-link-faq')
  if (toggleLinkFaq !== null) {
    for (let i = toggleLinkFaq.length - 1; i >= 0; i--) {
      toggleLinkFaq[ i ].addEventListener('click', function (event) {
        event.stopPropagation()
        let parent = event.target.closest('.tab-box-content')
        console.log(parent)
        toggleContainerFAQ(this, toggleLinkFaq, parent)
        return false
      })
    }
  }
}
