import $ from 'jquery'

/**
 * inline info toggle
 */
export default function initToggleInfo () {
  $(document).on('click', 'a[data-toggle-info]', function (e) {
    e.preventDefault()
    var infoContainer = $(this).children().first()

    if ($(infoContainer).hasClass('show-slide')) {
      $(infoContainer).addClass('hidden-slide').removeClass('show-slide')
    } else {
      $(infoContainer).addClass('show-slide').removeClass('hidden-slide')
    }
  })
}
