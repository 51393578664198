/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'
import domready from 'domready'
import baguetteBox from 'baguettebox.js'
import categoryInit from './modules/kacheln'
import initModal from './modules/modal'
import formSubmit from './modules/form-submit'
import initMaps from './modules/map'
import mobileNavigation from './modules/mobile-navigation'
import Swiper from 'swiper'
import notifier from './vendor/notifier'
import initToggle from './modules/toggle-container'
import toggleSub from './modules/toggle-sub-menu'
import initTilesToggle from './modules/tab-tiles-toggle.js'
import initDivorceForm from './modules/divorce-form-handler.js'
import initToggleInfo from './modules/toggleInfo.js'
// import debug from './modules/debug.js'

/**
 * Ab geht die Reise
 */
domready(() => {
  if (document.getElementById('message-notifer')) {
    console.log(document.getElementById('message-notifer'))
    notifier.show(document.getElementById('message-notifer').getAttribute('data-message'), '', document.getElementById('message-notifer').getAttribute('data-status'), '', 4000)
  }
  mobileNavigation()
  categoryInit()
  initModal()
  formSubmit()
  initMaps()
  toggleSub()
  initToggle()
  initTilesToggle()
  initDivorceForm()
  initToggleInfo()
  // debug()

  // eslint-disable-next-line
  new Swiper('#mmeinungen', {
    effect: 'slide',
    slidesPerView: 1,
    autoplay: 5000,
    speed: 2000,
    loop: true,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev'
  })
// eslint-disable-next-line
  new Swiper('.leistungen-slider', {
    slidesPerView: 3,
    slidesPerColumn: 1,
    spaceBetween: 0,
    speed: 2000,
    nextButton: '.leistung-button-next',
    prevButton: '.leistung-button-prev',
    loop: true,
    breakpoints: {
      568: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 0
      }
    }
  })
  // eslint-disable-next-line
  baguetteBox.run('.gallery')
// eslint-disable-next-line
})
