import $ from 'jquery'
import flatpickr from 'flatpickr'

function childrenInputs (number) {
  let s = '<div class="child">'
  s += '<label for="child_' + number + '_name"><span>Name des ' + number + '. Kindes</span>'
  s += '<input type="text" name="child_' + number + '_name" id="child_' + number + '_name" placeholder="Vorname, Nachname Kind ' + number + '"/></label>'
  s += '<label for="child_' + number + '_date_of_birth"><span>Geburtsdatum</span>'
  s += '<input type="date" name="child_' + number + '_date_of_birth" id="child_' + number + '_date_of_birth" placeholder="Geburtsdatum Kind ' + number + '"/></label>'
  s += '<label for="child_' + number + '_residence"><span>Anschrift des ' + number + '. Kindes</span>'
  s += '<input type="text" name="child_' + number + '_residence" id="child_' + number + '_residence" placeholder="Anschrift Kind ' + number + '"/></label>'
  s += '</div>'
  return s
}

export default function initDivorceForm () {
  $(document).on('change', '#divorceFormChildren', function (event) {
    if ($(this).get(0).checked) {
      $('.number-of-children').removeClass('hide').addClass('show')
    } else {
      $('.number-of-children').removeClass('show').addClass('hide')
      $('#divorceFormNumberOfChildren').val(0)
      $('.subFormChildren--inputs').empty()
    }
  })

  $(document).on('change', '#divorceFormNumberOfChildren', function (event) {
    // console.log( $(this).val() )
    $('.subFormChildren--inputs').empty()
    for (let i = 0; i < $(this).val(); i++) {
      $('.subFormChildren--inputs').append(childrenInputs(i + 1))
    }
  })

  $(document).on('change', '#divorceFormCosts', function (event) {
    if ($(this).get(0).checked) {
      $('.vkh-form-links').removeClass('hide').addClass('show')
    } else {
      $('.vkh-form-links').removeClass('show').addClass('hide')
    }
  })

  $(document).on('focus', '.subFormChildren input[type="date"]', function () {
    flatpickr('.subFormChildren input[type="date"]')
  })

  flatpickr('input[type="date"]')
}
